import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import GraphQLErrorList from "../components/Blog/graphql-error-list";
import { mapEdgesToNodes } from "../lib/helpers";
import CallToAction from "../components/Repeating/CTA";

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Homepage.jpg" }
    ) {
      publicURL
    }
    caseResultWithAmount: allSanityCaseResult(
      filter: { amount: { ne: null } }
      sort: { fields: [amount], order: DESC }
    ) {
      edges {
        node {
          amount
          settled
          title
          description
        }
      }
    }
    caseResultWithoutAmount: allSanityCaseResult(
      filter: { amount: { eq: null } }
    ) {
      edges {
        node {
          amount
          settled
          title
          description
        }
      }
    }
  }
`;

const Page = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const caseResultWithAmountNodes =
    data &&
    data.caseResultWithAmount &&
    mapEdgesToNodes(data.caseResultWithAmount);

  const caseResultWithoutAmountNodes =
    data &&
    data.caseResultWithoutAmount &&
    mapEdgesToNodes(data.caseResultWithoutAmount);

  return (
    <Layout>
      <SearchEngineOptimization
        title="Case Results | Jorgensen, Brownell & Pepin, P.C."
        description="Case Results. Jorgensen, Brownell & Pepin, P.C. is a full-service law firm in Colorado. Schedule a consultation with a Longmont attorney at the firm today"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="pt-12 md:pt-16 pb-20 md:pb-32">
        <div className="container">
          <header className="mb-12 md:mb-16">
            <h1 className="mb-0">Case Results</h1>
          </header>

          <div className="grid md:grid-cols-2 gap-8 md:gap-12">
            {caseResultWithAmountNodes &&
              caseResultWithAmountNodes.length > 0 &&
              caseResultWithAmountNodes.map((item, i) => (
                <div
                  key={i}
                  className="w-full bg-black rounded-lg py-6 md:py-12 px-6 md:px-10"
                >
                  <div className="heading-two text-primary-600 mb-7">
                    {item.settled
                      ? "Settled"
                      : "$" +
                        Number(item.amount).toLocaleString("en", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                  </div>
                  <div className="heading-three mb-7">{item.title}</div>
                  <p className="mb-0">{item.description}</p>
                </div>
              ))}

            {caseResultWithoutAmountNodes &&
              caseResultWithoutAmountNodes.length > 0 &&
              caseResultWithoutAmountNodes.map((item, i) => (
                <div
                  key={i}
                  className="w-full bg-black rounded-lg py-6 md:py-12 px-6 md:px-10"
                >
                  <div className="heading-two text-primary-600 mb-7">
                    {item.settled
                      ? "Settled"
                      : "$" +
                        Number(item.amount).toLocaleString("en", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                  </div>
                  <div className="heading-three mb-7">{item.title}</div>
                  <p className="mb-0">{item.description}</p>
                </div>
              ))}
          </div>
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

export default Page;
